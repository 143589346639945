import { formatCredentials } from '@hasura/auth-ui';

import { authEndpoints } from '../../../utils/constants';
import { api, APIRequestParams } from '../../../utils/request';
import { createQueryParamString } from '../../../utils/routeUtils';
import { getEncodedRedirectUrlParameter } from './utils/get-encoded-redirect-url-parameter';

export function resetPassword({
  email,
  search,
}: {
  email: string;
  search: string;
}) {
  const options: APIRequestParams = {
    credentials: 'include',
    body: JSON.stringify(formatCredentials({ email })),
    headers: {
      'content-type': 'application/json',
    },
  };

  const queryParamStringForRecoverUrl = createQueryParamString([
    { name: 'redirect_url' },
    { name: 'login_challenge' },
  ]);

  const url = queryParamStringForRecoverUrl
    ? `${authEndpoints.recover}?${queryParamStringForRecoverUrl}`
    : authEndpoints.recover;

  return api
    .post<{ message?: string; error?: string }>(url, options)
    .then(({ data }) => {
      if (data?.error) {
        throw new Error(data.error);
      }
      return {
        status: 'success',
        message:
          data?.message ?? 'Please check your email for further instructions!',
        search: getEncodedRedirectUrlParameter(search),
      };
    })
    .catch(err => {
      throw new Error(
        `Failed to recover your password: ${err?.message || 'Unknown error occurred'}`
      );
    });
}
