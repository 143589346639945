import { useMemo } from 'react';
import { AuthMode } from '@hasura/auth-ui';

import { AUTH_METHODS } from '../../utils/constants';

export const getAuthModes = (modes?: string | null) => {
  if (!modes) {
    return [];
  }

  const authMethods = modes.replace('saml', 'sso');

  if (authMethods) {
    return authMethods?.split(',') as AuthMode[];
  }

  return [];
};

export const useAuthModes = () => {
  const authModes = useMemo(() => getAuthModes(AUTH_METHODS), []);

  const isSocialSignInSupported = useMemo(
    () => authModes.includes('google') || authModes.includes('github'),
    [authModes]
  );

  const isEmailSignInSupported = useMemo(
    () => authModes.includes('password'),
    [authModes]
  );

  const isSSOAuthSupported = useMemo(
    () => authModes.includes('sso'),
    [authModes]
  );

  return {
    modes: authModes,
    isEmailSignInSupported,
    isSocialSignInSupported,
    isSSOAuthSupported,
  };
};
