import { formatCredentials } from '@hasura/auth-ui';
import { z } from 'zod';

import { authEndpoints } from '../../../utils/constants';
import { getAuthUrl } from './utils/get-auth-url';

const responseSchema = z.object({
  auth_url: z.string(),
});

export function signInWithSSO({
  email,
  search,
}: {
  email: string;
  search: string;
}): Promise<z.infer<typeof responseSchema>> {
  const options = {
    method: 'POST',
    body: JSON.stringify(formatCredentials({ email })),
  };

  return fetch(getAuthUrl({ baseUrl: authEndpoints.samlSSO, search }), options)
    .then(r => {
      if (r.status >= 500) {
        throw new Error('unexpected error has occurred');
      }
      if (r.status >= 400) {
        throw new Error('Email not registered with SSO');
      } else {
        return r.json();
      }
    })
    .then(resp => {
      const parsedResponse = responseSchema.safeParse(resp);

      if (!parsedResponse.success) {
        console.warn(
          'SSO Login response failed validation. Falling back to default auth_url location of "/". Error:',
          parsedResponse.error
        );
      }

      return parsedResponse.success ? parsedResponse.data : { auth_url: '/' };
    })
    .catch(err => {
      console.error('Error performing SSO', err.message);
      throw new Error('Unexpected failure with SSO. Please get in touch.');
    });
}
