import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../fragments/index';

export const GET_USER_INFO = gql`
  ${USER_FRAGMENT}
  query userInfo {
    users {
      ...UserFields
    }
  }
`;

export const GET_PERSONAL_ACCESS_TOKENS = gql`
  subscription getPersonalAccessTokens {
    user_personal_access_tokens(
      where: { is_active: { _eq: true } }
      order_by: { created_at: desc }
    ) {
      id
      description
      is_active
      created_at
      last_used
      last_used_ip_address
    }
  }
`;

export const GET_EMAIL_CHANGE_REQUESTS = gql`
  ${USER_FRAGMENT}
  query getEmailChangeRequests {
    change_email_request(
      where: {
        _and: [{ status: { _eq: "active" } }, { verified: { _eq: false } }]
      }
    ) {
      id
      status
      new_email
      verified
      resend_count
      user {
        ...UserFields
      }
    }
  }
`;

export const FETCH_USER_SUPPORT_TICKET_DETAILS = gql`
  query fetchUserSupportTicketDetails($limit: Int, $offset: Int) {
    fetchUserSupportTicketDetails(limit: $limit, offset: $offset) {
      id
      body
      status
      subject
      body
      created_at
      project_id
      project_name
      zendesk_link
      total_tickets
    }
  }
`;

export const GET_NEON_USER_INFO = gql`
  query getNeonUserInfo {
    neonGetUserInfo {
      isAuthenticated
      email
    }
  }
`;

export const GET_USER_ACTION_ACTIVITY = gql`
  query GetUserActionActivity($user_id: uuid!, $action: user_action!) {
    user_activity(
      where: { user_id: { _eq: $user_id }, action: { _eq: $action } }
    ) {
      metadata
    }
  }
`;

export const GET_MODEL_BASED_ENTERPRISE_CLOUD_COMMITMENTS = gql`
  query ModelBasedCommitments($userId: uuid!) {
    model_based_enterprise_cloud_commitments(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      support_plan
      commitment_start
      commitment_end
      yearly_commitment
      logical_model_unit_price
      table_unit_price
      collection_unit_price
    }
  }
`;
