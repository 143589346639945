import { getConsolePlatformType, isConsoleUrl } from './login-utils';

export const getEncodedRedirectUrlParameter = (search: string) => {
  // parse the passed in search portion of a url
  const parsed = new URLSearchParams(search);

  const redirectUrl = parsed.get('redirect_url');

  if (redirectUrl) {
    try {
      // parse the redirect url query param
      const parsedUrl = new URL(redirectUrl);

      // get any query params from the redirect url
      const redirectUrlParams = new URLSearchParams(parsedUrl.search);

      // remove existing platform query param if it exists
      if (redirectUrlParams.has('platform')) {
        redirectUrlParams.delete('platform');
      }

      // if the redirect url is a console url, set the platform query param
      if (isConsoleUrl(redirectUrl)) {
        redirectUrlParams.set('platform', getConsolePlatformType(redirectUrl));
      }

      // update the redirect url with the new query params
      parsedUrl.search = redirectUrlParams.toString();

      // encode the redirect url and return it so it can be used as a query param
      return encodeURIComponent(parsedUrl.toString());
    } catch (e) {
      console.warn('Error handling redirect url:', redirectUrl, 'Error:', e);
      return encodeURIComponent('/');
    }
  }

  // login_challenge is primarily used for CLI login
  if (parsed.has('login_challenge')) {
    // pass location.href through new URL to normalize the url
    const currentUrl = new URL(location.href).toString();

    // if login_challenge is present in the search params, then return the entire current url:
    return encodeURIComponent(currentUrl);
  }

  return encodeURIComponent('/');
};
