import { formatNewUserData, type RegisterData } from '@hasura/auth-ui';

import { authEndpoints } from '../../../utils/constants';
import { parseAuthResponse } from '../../../utils/helpers';
import { getAuthUrl } from './utils/get-auth-url';

export function registerNewUser({
  search,
  ...data
}: RegisterData & { search: string }): Promise<{
  status: string;
  message: string;
}> {
  const options: RequestInit = {
    credentials: 'include',
    body: JSON.stringify(formatNewUserData(data)),
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  };

  return fetch(
    getAuthUrl({
      baseUrl: authEndpoints.signup,
      search,
      loginChallengeAsQueryParam: true,
      redirectUrlParamName: 'redirect_url',
    }),
    options
  )
    .then(resp => {
      return resp.json();
    })
    .then(resp => {
      if ('status' in resp && resp.status === 'failure') {
        throw new Error(`Sign Up Error: ${parseAuthResponse(resp.errors)}`);
      }
      if ('status' in resp && resp.status === 'success') {
        return {
          status: 'success',
          message:
            'Sign Up success! Check your email to confirm your account and login!',
        };
      } else {
        throw new Error('Unexpected error. Get in touch with us for more info');
      }
    })
    .catch(err => {
      throw Error(`Sign Up Error: ${err.message ?? err.toString()}`);
    });
}
