import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

import { getSessionRole } from '../../../utils/auth';
import { EE_MODE, USER_ASSUMPTION_ROLE } from '../../../utils/constants';
import { heapAddProperties } from '../../../utils/heapAnalytics';
import { isHasuraDomain } from '../../../utils/helpers';
import {
  getPersistedUASessionEnd,
  persistUASessionEnd,
} from '../../../utils/localStorage';
import {
  Feature_Enum,
  FeatureAccessMap,
  FeatureFlags,
  HerokuSession,
  Plan,
  User,
} from '../../../utils/types';
import { EnterpriseUsersDomainResponse } from './useEnterpriseDomainUser';

type Props = {
  user: User;
  herokuSession: HerokuSession | null;
  children: React.ReactNode;
  enterpriseDomainData?: EnterpriseUsersDomainResponse | undefined;
};

type GetAvailablePlanForChange = (plan: Plan) => Plan;

type DashboardContext = {
  featureAccess: FeatureAccessMap;
  herokuSession: HerokuSession | null;
  updateHerokuSession: (session: HerokuSession | null) => void;
  user: User;
  toggleSidebarModal: (show: boolean, modalType: any) => void;
  toggleConfirmBox: (show: boolean, modalType: string) => void;
  hideConfirmBox: () => void;
  hideSidebarModal: () => void;
  isSidebarModalActive?: boolean;
  isConfirmBoxActive?: boolean;
  modalType?: string;
  confirmBoxType?: string;
  // a ref to the right section container is required so that child components can use it to set scroll position
  // since the ref itself is set only after the component is mounted, we also need a function to set it
  containerRef: React.RefObject<HTMLElement> | null;
  setContainerRef: (ref: React.RefObject<HTMLElement> | null) => void;
  /**
   * Note: `isEnterpriseUser` is computed only after successful Login. It defaults to `false` till login.
   * So `isEnterpriseUser` should not be used in components that will be used prior to login like the landing page, Logo, etc,.
   */
  isEnterpriseUser: boolean;
  hasLegacyPlansAccess: boolean;
  availablePlans: Plan[];
  isCloudUser: boolean;
  hasNewPlansAccess: boolean;
  getAvailablePlanForChange: (plan: Plan) => Plan;
  isAssumedUser: boolean;
  isHasuraRewindEnabled: boolean;
};

// We setup a context to share commonly used data related to
// - user info
// - the features the current user has access to
// - heroku session if any
// Additionally, we use the dashboard context to share functions
// that can be used to show/hide the right side drawer(sidebar) modal.
//
// ## Sample usage of the siderbar functions
// ```javascript
// import { DashboardContext } from '../../store';
// const Component:React.FC<PropsType> = (props)=>{
//   const { isSidebarModalActive, toggleSidebarModal } = useContext(
//    DashboardContext
//    );
//   ...
//   return (
//      ...
//      {isSidebarModalActive && (
//       <SomeCustomModalComponent
//       />
//     )}
//    )
// }
// ```
export const DashboardContext = createContext<DashboardContext>({
  featureAccess: {},
  herokuSession: null,
  updateHerokuSession: () => null,
  user: null as any,
  toggleSidebarModal: (show: boolean, modalType: string) =>
    console.log(show, modalType),
  hideSidebarModal: () => null,
  toggleConfirmBox: (show: boolean, boxType: string) =>
    console.log(show, boxType),
  isSidebarModalActive: false,
  isConfirmBoxActive: undefined,
  hideConfirmBox: () => null,
  modalType: '',
  confirmBoxType: '',
  containerRef: null,
  setContainerRef: () => {},
  isEnterpriseUser: false,
  hasLegacyPlansAccess: false,
  availablePlans: [],
  isCloudUser: false,
  hasNewPlansAccess: false,
  getAvailablePlanForChange: () => Plan.CloudFree,
  isAssumedUser: false,
  isHasuraRewindEnabled: false,
});

export const DashboardContextProvider: React.FC<Props> = ({
  children,
  herokuSession: hSession,
  user,
  enterpriseDomainData,
}) => {
  const [state, setState] = useState({
    isSidebarModalActive: false,
    isConfirmBoxActive: false,
    modalType: '',
    confirmBoxType: '',
    containerRefState: null as React.RefObject<HTMLElement> | null,
  });

  const [herokuSession, setHerokuSession] = useState(hSession);
  const [sessionRole, setSessionRole] = useState<string>('');

  const setContainerRef = (ref: React.RefObject<HTMLElement> | null) => {
    setState({ ...state, containerRefState: ref });
  };

  const ldClient = useLDClient();
  const flags = useFlags();

  useEffect(() => {
    setHerokuSession(hSession);
  }, [hSession]);

  // When the user changes (on login or UA login)
  // we need to check the X-Hasura-Role session variable in
  // order to determine if this is a user assumption session
  useEffect(() => {
    const fetchSessionRole = async () => {
      // getSessionRole makes a network request
      const role = await getSessionRole();
      setSessionRole(role);
    };

    fetchSessionRole();
  }, [user]);

  useEffect(() => {
    if (sessionRole === USER_ASSUMPTION_ROLE) {
      // Only store a new timestamp if there is no existing
      // UA session in progress
      if (!getPersistedUASessionEnd()) {
        const sessionEnd = moment().add(15, 'minutes').valueOf();
        persistUASessionEnd(sessionEnd);
      }
    }
  }, [sessionRole]);

  const toggleSidebarModal = (show: boolean, type: string) => {
    if (type) {
      setState(s => ({
        ...s,
        modalType: type,
        isSidebarModalActive: !!show,
      }));
    }
  };

  const featureAccess: FeatureAccessMap = {};
  user.feature_accesses.forEach(f => {
    featureAccess[f.feature] = true;
  });

  const toggleConfirmBox = (show: boolean, type: string) => {
    setState(s => ({
      ...s,
      isConfirmBoxActive: !!show,
      confirmBoxType: type,
    }));
  };

  const hideConfirmBox = () => {
    setState(s => ({
      ...s,
      isConfirmBoxActive: false,
      confirmBoxType: '',
    }));
  };

  const hideSidebarModal = () => {
    const { isSidebarModalActive } = state;
    if (isSidebarModalActive) {
      setState(s => ({
        ...s,
        isSidebarModalActive: false,
        isConfirmBoxActive: false,
        modalType: '',
        confirmBoxType: '',
      }));
    }
  };

  const updateHerokuSession = (session: HerokuSession | null) => {
    setHerokuSession(session);
  };

  // Check if domain exists and is active in enterprise_users_domain
  const isEnterpriseUser = useMemo<boolean>(() => {
    if (EE_MODE) return true;

    const enterpriseDomain = enterpriseDomainData?.enterprise_users_domain[0];
    return (
      ((enterpriseDomain?.is_exempt_from_billing &&
        enterpriseDomain?.is_active) ||
        (user?.enterprise_users?.is_exempt_from_billing &&
          user?.enterprise_users?.is_active)) ??
      false
    );
  }, [enterpriseDomainData, EE_MODE]);

  const isAssumedUser = useMemo<boolean>(() => {
    return sessionRole === USER_ASSUMPTION_ROLE;
  }, [sessionRole]);

  // register user on ld
  if (ldClient) {
    ldClient.identify({
      kind: 'user',
      key: user.id,
      'user-email': user.email,
      'user-id': user.id,
      email: user.email,
    });

    // An example return value of allFlags()
    // {
    //   "db-latency-v1": false,
    //   "pricing-m0": false,
    //   "prometheus-usage-alerting": false,
    //   "same-tab-console": true,
    //   "show-familiarity-survey": false,
    //   "test-flag": true
    // }
    const ldFlags = ldClient.allFlags();

    const isInternalHasuraUser = !!isHasuraDomain(user.email);

    heapAddProperties(user.id, ldFlags, isInternalHasuraUser);
  }

  /**
   * A user is a cloud user if they have the
   * the 'CloudUser' or 'CloudNewPlans' feature accesses enabled
   */
  const isCloudUser = useMemo<boolean>(
    () =>
      !!user?.feature_accesses.find(
        f =>
          f.feature === Feature_Enum.CloudUser ||
          f.feature === Feature_Enum.CloudNewPlans
      ),
    [user, flags]
  );

  /**
   * A user has access to the new plans if they have the
   * 'CloudNewPlans' feature access enabled
   * and the pricing v2 feature flag is enabled
   */
  const hasNewPlansAccess = useMemo<boolean>(
    () =>
      !isEnterpriseUser &&
      !!(
        flags?.[FeatureFlags.PricingM0] &&
        user?.feature_accesses.find(
          f => f.feature === Feature_Enum.CloudNewPlans
        )
      ),
    [isEnterpriseUser, user, flags]
  );

  /**
   * Users who have Hasura Rewind report enabled for them on dashboard
   */
  const isHasuraRewindEnabled = flags?.[FeatureFlags.HasuraRewind];

  /**
   * lets us know if a user can create projects
   * on legacy plans
   */
  const hasLegacyPlansAccess = useMemo<boolean>(() => {
    if (!user) {
      return false;
    }

    if (isEnterpriseUser) {
      return true;
    }

    return !!user.feature_accesses?.find(f => f.feature === 'CloudUser');
  }, [user, isEnterpriseUser]);

  /**
   * lists the plans available to a user
   * for project creation
   */
  const availablePlans = useMemo<Plan[]>(() => {
    console.log('flags', featureAccess);
    const isCloudNewUser = featureAccess.CloudNewPlans;
    const isCloudUser = featureAccess.CloudUser;

    if (isCloudNewUser && isCloudUser) {
      return [Plan.CloudFreeV2, Plan.CloudShared, Plan.CloudPayg];
    } else if (isCloudNewUser) {
      return [Plan.CloudFreeV2, Plan.CloudShared];
    } else if (isCloudUser) {
      return [Plan.CloudFree, Plan.CloudPayg];
    } else return [];
  }, [hasLegacyPlansAccess, flags]);

  /**
   * an interchange matrix to determine
   * which plan a user can interchange to
   * from a given plan
   * e.g. CloudFree -> CloudPayg
   *      CloudFreeV2 -> CloudShared
   *      CloudPayg -> CloudFree
   *      CloudShared -> CloudFreeV2
   * @param plan
   * @returns Plan
   * @example
   * getAvailablePlanForChange(Plan.CloudFree) // returns Plan.CloudPayg
   */

  const getAvailablePlanForChange: GetAvailablePlanForChange = (plan: Plan) => {
    const planInterchangeMatrix = {
      [Plan.CloudFree]: Plan.CloudPayg,
      [Plan.CloudFreeV2]: Plan.CloudShared,
      [Plan.CloudPayg]: Plan.CloudFree,
      [Plan.CloudShared]: Plan.CloudFreeV2,
    };

    return planInterchangeMatrix[plan];
  };

  const value = useMemo(
    () => ({
      featureAccess,
      herokuSession,
      updateHerokuSession,
      toggleConfirmBox,
      toggleSidebarModal,
      hideSidebarModal,
      hideConfirmBox,
      isSidebarModalActive: state.isSidebarModalActive,
      isConfirmBoxActive: state.isConfirmBoxActive,
      user,
      modalType: state.modalType,
      confirmBoxType: state.confirmBoxType,
      containerRef: state.containerRefState,
      setContainerRef: setContainerRef,
      isEnterpriseUser,
      availablePlans,
      isCloudUser,
      hasLegacyPlansAccess,
      hasNewPlansAccess,
      getAvailablePlanForChange,
      isAssumedUser,
      isHasuraRewindEnabled,
    }),
    [
      featureAccess,
      herokuSession,
      state,
      user,
      isEnterpriseUser,
      availablePlans,
      isCloudUser,
      hasLegacyPlansAccess,
      hasNewPlansAccess,
      isAssumedUser,
      isHasuraRewindEnabled,
    ]
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
