import { registerNewUser } from './register-new-user';
import { resetPassword } from './reset-password';
import { signInWithEmail } from './sign-in-with-email';
import { signInWithSSO } from './sign-in-with-sso';

export const loginHandlers = {
  registerNewUser,
  resetPassword,
  signInWithEmail,
  signInWithSSO,
};
