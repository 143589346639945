import React, { useEffect, useMemo, useRef } from 'react';
import {
  AlertMessages,
  AuthHandler,
  AuthUIProps,
  HasuraAuthUI,
  isSSOError,
  MantineProvider,
  useRedirectUrlPersistence,
} from '@hasura/auth-ui';
import { notifications } from '@mantine/notifications';
import { useLocation } from 'react-router-dom';

import { authEndpoints } from '../../utils/constants';
import { authPaths } from '../../utils/routeUtils';
import { loginHandlers } from './login-handlers';
import { getAuthUrl } from './login-handlers/utils/get-auth-url';
import { wait } from './login-handlers/utils/js-utils';

// wrapper that applies the MantineProvider and styles to the AuthUI
type Props = {
  authModes: AuthUIProps['authModes'];
  afterLogin: (data: { loginRedirectLocation: string }) => void;
};

export function HasuraAuthUIWrapper({ afterLogin, authModes }: Props) {
  const hasNotifiedOfConfirmedEmail = useRef(false);
  const { search } = useLocation();

  useEffect(() => {
    if (
      new URLSearchParams(search).get('is_confirmed') === 'true' &&
      !hasNotifiedOfConfirmedEmail.current
    ) {
      notifications.show({
        color: 'green',
        message: 'Email confirmed successfully!',
      });
      hasNotifiedOfConfirmedEmail.current = true;
    }
  }, []);

  // OAuth2 failures can remove redirect_url from the search params,
  // the following hook makes sure its persisted for 5 minutes and replace when redirect_url is removed with OAuth2 error
  useRedirectUrlPersistence();

  const hasSSOError = isSSOError(search);

  const alertMessage = useMemo(() => {
    if (hasSSOError) {
      if (authModes.includes('sso')) {
        return AlertMessages.OAuthErrorSSOEnabled({
          ssoTo: { pathname: authPaths.sso(), search },
        });
      } else {
        return AlertMessages.OAuthError();
      }
    }
    return undefined;
  }, [authModes, hasSSOError, search]);

  return (
    <MantineProvider
      siblings={
        <>
          <link rel="stylesheet" type="text/css" href="/css/mantine-core.css" />
          <link
            rel="stylesheet"
            type="text/css"
            href="/css/mantine-notifications.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/css/mantine-custom.css"
          />
        </>
      }
    >
      <HasuraAuthUI
        alertMessage={alertMessage}
        authModes={authModes}
        authPaths={authPaths}
        onGithubSignIn={async () => {
          setTimeout(() => {
            window.location.assign(
              getAuthUrl({
                baseUrl: authEndpoints.githubOAuthUrl,
                search,
              })
            );
          }, 0);

          // suspend the promise for 3 seconds so that the UI stays in the loading state
          // while the user is redirected to the oauth url
          await wait(3000);
        }}
        onGoogleSignIn={async () => {
          setTimeout(() => {
            window.location.assign(
              getAuthUrl({
                baseUrl: authEndpoints.googleOAuthUrl,
                search,
              })
            );
          }, 0);

          // suspend the promise for 3 seconds so that the UI stays in the loading state
          // while the user is redirected to the oauth url
          await wait(3000);
        }}
        onEmailSignIn={props => {
          return loginHandlers
            .signInWithEmail(props)
            .then(data => {
              afterLogin({
                loginRedirectLocation: data.location,
              });
            })
            .catch<AuthHandler>(e => {
              return {
                formError: e.message?.includes('Invalid Credentials')
                  ? {
                      field: 'password',
                      message: 'Invalid email or password',
                    }
                  : undefined,
                notification: {
                  type: 'error',
                  message: e.message ?? 'Something went wrong',
                },
              };
            });
        }}
        onSSOSignIn={props => {
          return loginHandlers
            .signInWithSSO(props)
            .then<void>(async data => {
              setTimeout(() => {
                window.location.replace(data.auth_url);
              }, 0);
              await wait(500);
            })
            .catch<AuthHandler>(e => {
              return {
                notification: {
                  type: 'error',
                  message: e.message ?? 'Something went wrong',
                },
              };
            });
        }}
        onForgotPassword={props => {
          return loginHandlers
            .resetPassword(props)
            .then<AuthHandler>(data => {
              return {
                notification: {
                  type: 'success',
                  message: data.message,
                },
                search: data.search,
              };
            })
            .catch<AuthHandler>(e => {
              return {
                notification: {
                  type: 'error',
                  message: e.message ?? 'Something went wrong',
                },
              };
            });
        }}
        onRegister={props => {
          return loginHandlers
            .registerNewUser(props)
            .then<AuthHandler>(data => {
              return {
                notification: {
                  type: 'success',
                  message: data.message,
                },
              };
            })
            .catch<AuthHandler>(e => {
              return {
                notification: {
                  type: 'error',
                  message: e.message ?? 'Something went wrong',
                },
              };
            });
        }}
      />
    </MantineProvider>
  );
}
