import { getEncodedRedirectUrlParameter } from './get-encoded-redirect-url-parameter';

type GetAuthUrlProps = {
  baseUrl: string;
  search: string;
  loginChallengeAsQueryParam?: boolean;
  redirectUrlParamName?: 'redirect_url' | 'redir';
};

export const getAuthUrl = ({
  baseUrl,
  search,
  loginChallengeAsQueryParam = false,
  redirectUrlParamName = 'redir',
}: GetAuthUrlProps) => {
  const searchParams = new URLSearchParams(search);
  const redirectUrlParameter = getEncodedRedirectUrlParameter(search);
  const loginChallenge = searchParams.get('login_challenge');

  if (loginChallenge && loginChallengeAsQueryParam) {
    // this is used for the register flow only as of now
    const queryString = new URLSearchParams({
      login_challenge: loginChallenge,
    });
    return `${baseUrl}?${queryString}`;
  }

  const url = `${baseUrl}?${redirectUrlParamName}=${redirectUrlParameter}`;

  return url;
};
