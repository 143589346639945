import Tracker from '@openreplay/tracker';

import { IS_PRODUCTION, OPEN_REPLY_KEY } from './constants';

export let tracker: Tracker | null = null;

const OPENREPLAY_SESSION_COOKIE = 'openReplaySessionHash';

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}

function setCookie(name: string, value: string): void {
  const date = new Date();
  date.setTime(date.getTime() + 10 * 60 * 1000); // 10 minutes expiration
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;domain=.hasura.io;SameSite=Lax`;
}

export const startOpenReplayTracking = () => {
  if (tracker && IS_PRODUCTION) {
    const existingSessionHash = getCookie(OPENREPLAY_SESSION_COOKIE);
    if (existingSessionHash) {
      // Resume existing session
      tracker.start({
        sessionHash: existingSessionHash,
        metadata: {
          domain: window.location.hostname,
        },
      });
    } else {
      // Start a new session
      tracker.start({
        metadata: {
          domain: window.location.hostname,
        },
      });
      const newSessionHash = tracker.getSessionToken();
      if (newSessionHash) {
        setCookie(OPENREPLAY_SESSION_COOKIE, newSessionHash);
      }
    }
  } else {
    console.warn('OpenReplay tracker is not initialized');
  }
};

export const initializeOpenReplay = async () => {
  if (IS_PRODUCTION) {
    const { default: Tracker, SanitizeLevel } = await import(
      '@openreplay/tracker'
    );
    const projectKey = OPEN_REPLY_KEY;
    const ingestPoint = 'https://analytics-openreplay.hasura-app.io/ingest';
    tracker = new Tracker({
      projectKey,
      ingestPoint,
      // Add any other configuration options here
      domSanitizer: (node: Element) => {
        // Add any specific sanitization rules for cloud dashboard
        if (node.classList.contains('sensitive-data')) {
          return SanitizeLevel.Hidden;
        }
        return SanitizeLevel.Plain;
      },
    });
    startOpenReplayTracking();
  }
};

export const updateOpenReplayUserId = (userId: string) => {
  if (tracker) {
    tracker.setUserID(userId);
  }
};

export const trackOpenReplayEvent = (
  eventName: string,
  payload?: Record<string, any>
) => {
  if (tracker) {
    tracker.event(eventName, payload);
  }
};
